export default {
  "row": "qzd",
  "col": "qzI",
  "header": "qzt",
  "block": "qzM",
  "col-5": "qzP",
  "col-6": "qzl",
  "col-9": "qzX",
  "col-12": "qzC",
  "hide": "qzk"
};
