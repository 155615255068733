export default {
  "row": "qOr body-1",
  "active": "qOb",
  "cell": "qOy",
  "empty": "qOU",
  "ellipsis": "qOj",
  "text-secondary": "qOW",
  "transfer-type": "qOu",
  "transfer-type__icon": "qmS",
  "quick-actions": "qmc",
  "amount": "qmq",
  "cell-approve": "qmZ",
  "cell-type": "qmR",
  "cell-requester": "qmQ",
  "account-select": "qme",
  "row-compact": "qmB",
  "row-sidebar": "qmE",
  "cell-amount": "qmd",
  "hidden": "qmI",
  "animated": "qmt",
  "fadein-item": "qmM",
  "animated-cell": "qmP"
};
