export default {
  "row": "qTN",
  "item-bulk": "qTv",
  "active": "qTo",
  "disabled": "qTn",
  "cell": "qTi body-2",
  "not-disabled": "qTA",
  "item-amount-disabled": "qTY",
  "item-amount-credit": "qTh",
  "no-padding": "qTf",
  "cell-content": "qTK",
  "centered-cell": "qTG",
  "item-counterparty-icon": "qTr mr-16",
  "avatar": "qTb",
  "item-counterparty-name": "qTy",
  "item-counterparty-name-status": "qTU",
  "disputing-chargeback": "qTj",
  "item-counterparty-name-text": "qTW",
  "item-amount": "qTu body-1",
  "item-amount-foreign": "qzS",
  "attachment-icon": "qzc",
  "item-method": "qzq",
  "bank-account-avatar": "qzZ",
  "attachment-status-dot": "qzR",
  "attached": "qzQ",
  "missing": "qze",
  "item-attachment": "qzB",
  "fadein-item": "qzE"
};
