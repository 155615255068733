export default {
  "transaction": "qDx",
  "transaction-details": "qDw",
  "transaction-details-amount": "qDN body-1",
  "credit": "qDv",
  "negative-amount": "qDo",
  "transaction-details-description": "qDn body-2",
  "transaction-remove-cta": "qDi",
  "avatar": "qDA mr-16",
  "spinner": "qDY"
};
