export default {
  "container": "qVm",
  "header": "qVp",
  "title": "qVx caption-bold",
  "search-icon": "qVw",
  "text": "qVN body-2",
  "trigger": "qVv",
  "loading-state": "qVo",
  "dropzone-placeholder-wrapper": "qVn",
  "dropzone-placeholder": "qVi",
  "file-loading": "qVA"
};
