export default {
  "vat-lines": "qwA",
  "vat-line": "qwY",
  "vat-input": "qwh",
  "vat-rate": "qwf",
  "vat-option-button": "qwK",
  "disabled": "qwG",
  "vat-option-tooltip": "qwr",
  "remove-label": "qwb",
  "remove-button": "qwy",
  "vat-total": "qwU",
  "vat-total-amount": "qwj",
  "add-vat-line": "qwW",
  "with-vat-lines": "qwu"
};
