export default {
  "wrapper": "qFo",
  "section-wrapper": "qFn",
  "header": "qFi",
  "title": "qFA body-1",
  "close-icon": "qFY",
  "section": "qFh",
  "section-title": "qFf caption-bold",
  "labels": "qFK",
  "labels-title": "qFG",
  "section-checkbox": "qFr",
  "grow": "qFb scroll",
  "label": "qFy",
  "label-spacing": "qFU",
  "buttons": "qFj"
};
