export default {
  "sidebar-header": "qLg",
  "sidebar-header__top": "qLD",
  "sidebar-header__body": "qLV",
  "sidebar-header__content": "qLL",
  "sidebar-header__date": "qLT",
  "infobox": "qLz",
  "infobox--link": "qLa",
  "ghost": "qLH",
  "fail": "qLO",
  "r-transaction-disclaimer": "qLm",
  "hold-disclaimer": "qLp",
  "transaction-link": "qLx body-2",
  "icon-link": "qLw"
};
