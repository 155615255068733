export default {
  "wrapper": "qzH",
  "title": "qzO",
  "activity-tag": "qzm",
  "select": "qzp",
  "tooltip": "qzx",
  "date-picker": "qzw",
  "internal-notes": "qzN",
  "scheduled": "qzv"
};
