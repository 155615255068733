export default {
  "transfer-header": "qTE",
  "infobox": "qTd header-section",
  "proof-section": "qTI",
  "proof-link": "qTt",
  "download-icon": "qTM",
  "cancel-button": "qTP",
  "request-refund-button": "qTl",
  "refund-requested-indicator": "qTX",
  "recall-indicator": "qTC",
  "payment-purpose": "qTk",
  "payment-purpose-label": "qTJ",
  "repeat-infobox": "qTs qTd header-section",
  "additional-infobox": "qTF qTd header-section",
  "repeat-transfer-action": "qTg",
  "financing-not-compliant-disclaimer": "qTD"
};
