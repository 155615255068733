export default {
  "row": "qmY body-1",
  "active": "qmh",
  "cell": "qmf",
  "empty": "qmK",
  "ellipsis": "qmG",
  "text-secondary": "qmr",
  "cell-type": "qmb",
  "cell-reason": "qmy",
  "cell-amount": "qmU",
  "row-sidebar": "qmj",
  "animated": "qmW",
  "fadein-item": "qmu",
  "animated-cell": "qpS"
};
