export default {
  "display-block": "qpA",
  "pay-later-tooltip": "qpY",
  "disabled": "qph",
  "stepper": "qpf",
  "installments": "qpK",
  "flex-items": "qpG",
  "day-label": "qpr body-2",
  "installment-amount": "qpb body-1",
  "spacing": "qpy",
  "info-icon": "qpU",
  "detail": "qpj",
  "detail-value": "qpW",
  "extra": "qpu",
  "extra-value": "qxS",
  "badge": "qxc",
  "title": "qxq",
  "link": "qxZ"
};
