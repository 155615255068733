export default {
  "row": "qOo",
  "cell": "qOn",
  "empty": "qOi",
  "icon": "qOA",
  "type": "qOY",
  "amount-content": "qOh",
  "cell-approve": "qOf",
  "approve-content": "qOK",
  "row-compact": "qOG"
};
