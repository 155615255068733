export default {
  "header-cell": "qTT",
  "header-content": "qTz caption-bold",
  "active": "qTa",
  "align-right": "qTH",
  "empty": "qTO",
  "align-checkbox": "qTm",
  "col-5": "qTp",
  "col-9": "qTx",
  "emitted-at-header-tag": "qTw"
};
