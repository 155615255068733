export default {
  "beneficiary-details": "qpc",
  "beneficiary-account-types": "qpq",
  "divider": "qpZ",
  "label": "qpR",
  "labels": "qpQ",
  "error": "qpe",
  "link": "qpB caption",
  "sticky-panel": "qpE"
};
