export default {
  "custom-export-loading": "qgb",
  "title": "qgy",
  "description": "qgU",
  "section": "qgj",
  "placeholder-avatar": "qgW",
  "_with-avatar": "qgu",
  "_with-list": "qDS",
  "list-item": "qDc",
  "radio": "qDq",
  "header": "qDZ",
  "header-line": "qDR"
};
