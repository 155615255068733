export default {
  "container": "qVd",
  "item": "qVI caption-bold",
  "hover": "qVt",
  "active": "qVM",
  "name-wrapper": "qVP",
  "name": "qVl body-2",
  "count": "qVX",
  "actions-placeholder": "qVC",
  "actions": "qVk",
  "show": "qVJ",
  "action-button": "qVs"
};
