export default {
  "beneficiary-details": "qay",
  "beneficiary-country-disclaimer": "qaU",
  "beneficiary-account-types": "qaj",
  "divider": "qaW",
  "error-disclaimer": "qau",
  "label": "qHS",
  "edition-info-disclaimer": "qHc",
  "sticky-panel": "qHq",
  "spinner": "qHZ"
};
