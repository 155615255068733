export default {
  "row": "qwq",
  "col": "qwZ",
  "counterparty": "qwR",
  "header": "qwQ",
  "icon": "qwe",
  "col-12": "qwB",
  "col-8": "qwE",
  "col-6": "qwd",
  "col-4": "qwI",
  "col-2": "qwt",
  "hide": "qwM"
};
