export default {
  "fileupload": "qwa",
  "file": "qwH",
  "label": "qwO",
  "zone": "qwm",
  "error": "qwp",
  "hide": "qwx",
  "dropzone": "qww",
  "hidden": "qwN",
  "upload-icon": "qwv",
  "dropping": "qwo",
  "bounce2": "qwn",
  "error-message": "qwi"
};
