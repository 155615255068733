export default {
  "header-cell": "qmg",
  "empty": "qmD",
  "header-content": "qmV caption-bold",
  "active": "qmL",
  "header-type": "qmT",
  "header-reason": "qmz",
  "header-status": "qma",
  "header-amount": "qmH",
  "row-sidebar": "qmO",
  "hidden": "qmm",
  "animated": "qmp",
  "fadein-item": "qmx",
  "animated-cell": "qmw"
};
