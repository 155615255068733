export default {
  "row": "qxn",
  "counterparty-name": "qxi",
  "counterparty-status": "qxA",
  "amount": "qxY",
  "next-date": "qxh",
  "end-date": "qxf",
  "frequency": "qxK",
  "active": "qxG",
  "cell": "qxr body-2",
  "no-padding": "qxb",
  "cell-content": "qxy",
  "counterparty": "qxU",
  "status-avatar": "qxj",
  "beneficiary-name": "qxW",
  "empty": "qxu",
  "disabled": "qwS",
  "fadein-item": "qwc"
};
