export default {
  "sidebar-content": "qzh",
  "section": "qzf",
  "header-wrapper": "qzK",
  "transfer-id": "qzG caption",
  "small-padding": "qzr",
  "title": "qzb",
  "subtitle": "qzy",
  "actions": "qzU"
};
