/* import __COLOCATED_TEMPLATE__ from './schedule-transfer-selector.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { SCHEDULE_OPERATION_TYPES } from 'qonto/constants/standing-orders';
import { OPERATION_TYPES } from 'qonto/constants/transfers';
import { getCurrentParisDateString } from 'qonto/utils/date';

export default class TransferFormScheduleTransferSelector extends Component {
  disclaimerInline = Disclaimer.Inline;

  @service intl;
  @service segment;
  @service featuresManager;

  @tracked scheduled = this._getTransferWasScheduled();

  editOperationTypes = [
    SCHEDULE_OPERATION_TYPES.WEEKLY,
    SCHEDULE_OPERATION_TYPES.MONTHLY,
    SCHEDULE_OPERATION_TYPES.QUARTERLY,
    SCHEDULE_OPERATION_TYPES.HALF_YEARLY,
    SCHEDULE_OPERATION_TYPES.YEARLY,
  ];

  get operationTypes() {
    return this.args.operationTypes || [];
  }

  operationTypeTranslations = {
    [SCHEDULE_OPERATION_TYPES.SCHEDULED]: this.intl.t('transfers.frequency.scheduled'),
    scheduled_later: this.intl.t('transfers.frequency.scheduled_later'),
    fx_scheduled: this.intl.t('transfers.frequency.fx_scheduled'),
    [SCHEDULE_OPERATION_TYPES.WEEKLY]: this.intl.t('transfers.frequency.standing_weekly'),
    standing_weekly_short: this.intl.t('transfers.frequency.standing_weekly_short'),
    [SCHEDULE_OPERATION_TYPES.MONTHLY]: this.intl.t('transfers.frequency.standing_monthly'),
    standing_monthly_gender: this.intl.t('transfers.frequency.standing_monthly_gender'),
    [SCHEDULE_OPERATION_TYPES.QUARTERLY]: this.intl.t('transfers.frequency.standing_quarterly'),
    [SCHEDULE_OPERATION_TYPES.HALF_YEARLY]: this.intl.t('transfers.frequency.standing_half_yearly'),
    [SCHEDULE_OPERATION_TYPES.YEARLY]: this.intl.t('transfers.frequency.standing_yearly'),
    [OPERATION_TYPES.PAY_LATER]: this.intl.t('transfers.frequency.scheduled'),
  };

  get scheduledDate() {
    return this.args.transfer.scheduledDate;
  }

  get optionArray() {
    return this.args.transfer.id ? this.editOperationTypes : this.operationTypes;
  }

  get minDate() {
    return dayjs().add(1, 'day').startOf('day').format(DATE_PICKER_FIELD_FORMAT);
  }

  get endMinDate() {
    let scheduledDate = this.args.transfer.scheduledDate;

    if (scheduledDate) {
      return dayjs(scheduledDate).add(1, 'day').format(DATE_PICKER_FIELD_FORMAT);
    }

    return this.minDate;
  }

  _getTransferWasScheduled() {
    let {
      transfer: { scheduledDate, isPayLater, isScheduled },
    } = this.args;

    if (isPayLater) {
      return false;
    }

    return !(isScheduled && dayjs(scheduledDate).isToday());
  }

  get disclaimer() {
    let {
      isStandingWeekly,
      isStandingMonthly,
      isStandingQuarterly,
      isStandingHalfYearly,
      isStanding,
      standingEndingDate,
      scheduledDate,
      occurrencesCount,
    } = this.args.transfer;

    if (!isStanding || !scheduledDate) {
      return null;
    }

    let disclaimerOptions = {
      startDate: dayjs(scheduledDate).toDate(),
      endDate: dayjs(standingEndingDate).toDate(),
      transferOccurences: occurrencesCount,
    };

    if (isStandingWeekly) {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_weekly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_weekly_frequency_unlimited', disclaimerOptions);
    } else if (isStandingMonthly) {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_monthly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_monthly_frequency_unlimited', disclaimerOptions);
    } else if (isStandingQuarterly) {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_quarterly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_quarterly_frequency_unlimited', disclaimerOptions);
    } else if (isStandingHalfYearly) {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_half_yearly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_half_yearly_frequency_unlimited', disclaimerOptions);
    } else {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_yearly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_yearly_frequency_unlimited', disclaimerOptions);
    }
  }

  @action handleOperationType(operationType) {
    let { transfer } = this.args;

    transfer.changeOperationType(operationType);
    transfer.standingOn = null;
  }

  @action setScheduledDate(date) {
    let { transfer } = this.args;

    transfer.scheduledDate = date;

    if (dayjs(transfer.standingEndingDate).isBefore(this.endMinDate)) {
      transfer.standingEndingDate = this.endMinDate;
    }
  }

  @action setStandingDate(date) {
    this.args.transfer.standingEndingDate = date;
  }

  @action handleToggleScheduled(transfer) {
    let today = getCurrentParisDateString();

    transfer.scheduledDate = today;
    this._toggleScheduledState();

    if (this.scheduled) {
      this.segment.track('transfer_schedule_checkbox');
    }
  }

  _toggleScheduledState() {
    let { transfer } = this.args;

    this.scheduled = !this.scheduled;

    if (transfer.isNew && !transfer.isScheduled) {
      transfer.changeOperationType('scheduled');
    }

    if (this.scheduled) {
      let scheduledDate = transfer.scheduledDate;

      if (dayjs(scheduledDate).isBefore(this.minDate)) {
        transfer.scheduledDate = this.minDate;
      }
    }
  }
}
