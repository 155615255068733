export default {
  "filters-checkbox": "qDs mb-8",
  "csv-options-button": "qDF",
  "active": "qDg",
  "options-format-settings-wrapper": "qDD",
  "checkbox": "qDV",
  "options-format-settings": "qDL",
  "hidden": "qDT",
  "visible": "qDz",
  "disabled": "qDa",
  "buttons": "qDH"
};
