export default {
  "transfer-form": "qHs",
  "transfer-form-title": "qHF",
  "transfer-form-subtitle": "qHg",
  "form": "qHD",
  "form-footer": "qHV",
  "footer-btn": "qHL",
  "header": "qHT",
  "main-settings": "qHz",
  "additional-settings": "qHa",
  "form-container": "qHH"
};
