export default {
  "card-header": "qLE",
  "counterparty-name": "qLd",
  "pan-section": "qLI header-section body-2",
  "infobox": "qLt header-section",
  "see-card-button": "qLM",
  "card-identifier-section": "qLP",
  "card-icon": "qLl",
  "nickname": "qLX body-1",
  "pan": "qLC body-2"
};
