export default {
  "header-cell": "qxz",
  "align-right": "qxa",
  "header-content": "qxH caption-bold",
  "active": "qxO",
  "empty": "qxm",
  "col-12": "qxp",
  "col-8": "qxx",
  "col-6": "qxw",
  "col-4": "qxN",
  "col-2": "qxv",
  "hide": "qxo"
};
