export default {
  "header-cell": "qOD",
  "empty": "qOV",
  "header-type": "qOL",
  "header-requester": "qOT",
  "header-amount": "qOz",
  "header-approval": "qOa",
  "header-content": "qOH caption-bold",
  "active": "qOO",
  "row-compact": "qOm",
  "row-sidebar": "qOp",
  "hidden": "qOx",
  "animated": "qOw",
  "fadein-item": "qON",
  "animated-cell": "qOv"
};
