export default {
  "modal": "qgC",
  "section": "qgk",
  "header": "qgJ",
  "title": "qgs",
  "icon": "qgF",
  "content": "qgg",
  "export-template-wrapper": "qgD",
  "export-template-radio": "qgV",
  "export-template-content": "qgL",
  "export-template-label": "qgT",
  "export-template-button": "qgz",
  "export-template-list": "qga",
  "inactive": "qgH",
  "export-template-title": "qgO",
  "export-template-description": "qgm",
  "export-template-item-content": "qgp",
  "permission-disclaimer-container": "qgx",
  "export-template-footer": "qgw",
  "export-template-cta-container": "qgN",
  "upsell": "qgv",
  "upsell-section": "qgo",
  "_content": "qgn",
  "_cta": "qgi",
  "upsell-title": "qgA",
  "upsell-description": "qgY"
};
