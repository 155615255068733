export default {
  "modal": "qgS",
  "section": "qgc",
  "header": "qgq",
  "title": "qgZ",
  "separator-title": "qgR",
  "icon": "qgQ",
  "formats": "qge",
  "columns": "qgB",
  "column-list": "qgE",
  "draggable-column": "qgd",
  "column-field": "qgI",
  "column-selector": "qgt",
  "column-with-error": "qgM",
  "handle": "qgP",
  "label-color": "qgl",
  "disclaimer": "qgX"
};
