export default {
  "wrapper": "qaS",
  "ember-power-select-dropdown": "qac",
  "status": "qaq body-1",
  "error": "qaZ",
  "success": "qaR",
  "type-container": "qaQ",
  "type-close": "qae",
  "date": "qaB",
  "close-icon": "qaE",
  "container": "qad",
  "container-picto": "qaI",
  "avatar": "qat",
  "container-picto-status": "qaM",
  "general": "qaP",
  "general-amount": "qal",
  "unprocessed": "qaX",
  "fx-rate": "qaC",
  "general-counterparty": "qak body-2",
  "infobox": "qaJ",
  "account-infobox": "qas qaJ",
  "initiator-infobox": "qaF qaJ",
  "transaction-infobox": "qag qaJ",
  "repeat-infobox": "qaD qaJ",
  "item": "qaV body-2",
  "eye-icon": "qaL",
  "repeat-transfer-action": "qaT",
  "disclaimer": "qaz",
  "start-verification-link": "qaa",
  "financing-not-compliant-disclaimer": "qaH"
};
