export default {
  "button-wrapper": "qDh",
  "btn-filter": "qDf",
  "name-wrapper": "qDK",
  "preset-name": "qDG",
  "cancel-placeholder": "qDr",
  "cancel-button": "qDb",
  "dropdown-opened": "qDy",
  "dropdown-closed": "qDU",
  "dropdown-menu": "qDj",
  "loading-item": "qDW",
  "placeholder": "qDu",
  "list-body": "qVS",
  "list-item": "qVc",
  "error-state": "qVq body-2",
  "warning": "qVZ",
  "warning-icon": "qVR",
  "footer": "qVQ",
  "add-icon": "qVe",
  "empty-state": "qVB"
};
